import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { Rollset, RollsetRolls } from "../models/Rollset";
import {
  getRollsetRolls,
  getRollsets,
} from "../repositories/RollsetRepository";
import { TableRollset } from "./components/TableRollset";

type useRollsetsProps = {
  onError: (error: Error) => void;
  rollsetId?: number;
  onSuccess?: (data: any[]) => void;
};

export const useRollsets = ({ rollsetId, onError }: useRollsetsProps) => {
  return useQuery<Rollset[], Error>({
    queryKey: ["rollsets", rollsetId],
    queryFn: () => getRollsets(rollsetId),
    onError: onError,
  });
};

export const useRollsetRolls = ({
  rollsetId,
  onError,
  onSuccess,
}: useRollsetsProps) => {
  return useQuery<RollsetRolls[], Error>({
    queryKey: ["rollsetRolls", rollsetId],
    queryFn: () => getRollsetRolls(rollsetId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export const RollsetPage = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1}>
            <TableRollset />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
