import { Grid, GridSize, TextField } from "@material-ui/core";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { LocationTreeSelector } from "../../../../shared/selectors";

type PartFieldKeys = "RFIDTag" | "PartName" | "LocationName";

type Props<T extends FieldValues> = {
  control: Control<T>;
  disabled?: boolean | { PartName?: boolean; Location?: boolean };
  hideTag?: boolean;
  locationSizeMd?: GridSize;
};

export const PartFields = <T extends Record<PartFieldKeys, any>>({
  control,
  disabled,
  hideTag = false,
  locationSizeMd = 12,
}: Props<T>) => {
  let disabledName: boolean;
  let disabledLocation: boolean;

  if (typeof disabled === "boolean") {
    // If disabled is a boolean, use it for both
    disabledName = disabled;
    disabledLocation = disabled;
  } else if (disabled && typeof disabled === "object") {
    // If disabled is an object, use its properties or false if not provided
    disabledName = disabled.PartName ?? false;
    disabledLocation = disabled.Location ?? false;
  } else {
    // If disabled is not provided or is invalid, default to false
    disabledName = false;
    disabledLocation = false;
  }

  return (
    <>
      <Grid item md={6} sm={12} xs={12}>
        <Controller<T>
          name={"PartName" as Path<T>}
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Name"
              variant="outlined"
              {...field}
              margin="dense"
              size="small"
              fullWidth
              disabled={disabledName}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      {!hideTag && (
        <Grid item md={6} sm={12} xs={12}>
          <Controller<T>
            name={"RFIDTag" as Path<T>}
            control={control}
            render={({ field }) => (
              <TextField
                label="Tag"
                variant="outlined"
                {...field}
                margin="dense"
                size="small"
                fullWidth
                disabled
              />
            )}
          />
        </Grid>
      )}
      <Grid
        item
        md={locationSizeMd}
        sm={12}
        xs={12}
        style={{ marginTop: hideTag ? 0 : -8 }}
      >
        <Controller<T>
          name={"LocationId" as Path<T>}
          control={control}
          render={({ field }) => (
            <LocationTreeSelector
              label="Location"
              value={field.value}
              onChange={field.onChange}
              disabled={disabledLocation}
            />
          )}
        />
      </Grid>
    </>
  );
};
