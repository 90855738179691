import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { EdgerRoll } from "../../models/parts/EdgerRoll";

export const getEdgerRolls = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

const resp = await apiService.callV2("[M2].[GetEdgerRolls]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as EdgerRoll[];
	}
	else {
		throw new Error(resp.message || "Error fetching arbors");
	}
};
export const upsertEdgerRoll = async (roll: EdgerRoll) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: roll.PartId },
		{ name: "PartName", value: roll.PartName },
		{ name: "LocationId", value: roll.LocationId },
		{ name: "RollPosition", value: roll.RollPosition },
		{ name: "RollStatusCode", value: roll.RollStatusCode },
		{ name: "Diameter", value: roll.Diameter },
		{ name: "NewDiameter", value: roll.NewDiameter },
		{ name: "ScrapDiameter", value: roll.ScrapDiameter },
	];

	const resp = await apiService.callV2("[M2].[UpsertEdgerRoll]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting roll");
	}
};

export const deleteEdgerRoll = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteRoll]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting roll");
	}
};