import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../controls/buttons/GridButtons";
import { partTypeCodes } from "../components/TableParts";
import { Part } from "../models/Part";

type Props = {
  setSelectedRowData: (arbor: Part) => void;
  setShowDetails: (
    modal: "U" | "E" | "H" | "C" | "G" | "A" | "T" | "K" | "D" | ""
  ) => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setSelectedRowData,
  setShowDetails,
  showContextMenu,
}: Props) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "TagId",
      headerName: "Tag",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "PartTypeCode",
      headerName: "Type",
      minWidth: 20,
      flex: 1,
      valueGetter: (params) =>
        partTypeCodes.find(
          (part) => part.Code === (params.data as Part).PartTypeCode
        )?.Name,
      cellStyle: cellStyle,
    },
  ];
  return { columnDefs };
};
