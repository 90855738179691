import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CatalogSectionFamily } from "../models/CatalogSectionFamily";

export const getSectionFamilies = async (sectionFamilyId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (sectionFamilyId)
		parameters.push({ name: "SectionFamilyId", value: sectionFamilyId });

	const resp = await apiService.callV2("[M2].[GetSectionFamilies]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CatalogSectionFamily[];
	}
	else {
		throw new Error(resp.message || "Error fetching section families");
	}
};
export const upsertSectionFamily = async (sectionFamily: CatalogSectionFamily) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionFamilyId", value: sectionFamily.SectionFamilyId },
		{ name: "FamilyName", value: sectionFamily.SectionFamilyName }
	];

	const resp = await apiService.callV2("[M2].[UpsertSectionFamily]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error upserting section families");
	}

};

export const deleteSectionFamily = async (sectionFamilyId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionFamilyId", value: sectionFamilyId }
	];

	const resp = await apiService.callV2("[M2].[DeleteSectionFamily]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting section families");
	}
};