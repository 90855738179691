import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { Donut } from "../../../../models/parts/Donut";

type Props = {
  setSelectedRowData: (donut: Donut) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useDonutOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: Donut) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Donut",
        key: "new_donut",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Donut",
          key: "edit_donut",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Donut",
          key: "delete_donut",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
