import { Part, PART_INITIAL_VALUES } from "./Part";

export interface Cassette extends Part {
	RivCassetteChockId: number | null;
	RivCassetteChockName: string | null;
	AveCassetteChockId: number | null;
	AveCassetteChockName: string | null;
	IsActive: boolean | null;
}

export const CASSETTE_INITIAL_VALUES: Cassette = {
	...PART_INITIAL_VALUES,
	RivCassetteChockId: null,
	RivCassetteChockName: null,
	AveCassetteChockId: null,
	AveCassetteChockName: null,
	IsActive: true
};