import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Carrier } from "../models/Carrier";

export const getCarriers = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetCarriers]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Carrier[];
	}
	else {
		throw new Error(resp.message || "Error fetching carriers");
	}
};

type createRollsetProps = {
	UpperRollId: number | null;
	LowerRollId: number | null;
	SectionId: number | null;
	StandId: number | null;
}
export const createRollset = async ({UpperRollId, LowerRollId, SectionId, StandId}: createRollsetProps) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "UpperRollId", value: UpperRollId },
		{ name: "LowerRollId", value: LowerRollId },
		{ name: "SectionId", value: SectionId },
		{ name: "StandId", value: StandId },
	]

	const resp = await apiService.callV2("[M2].[CreateRollset]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error creating rollset");
	}
};

export const assemblyCarrier = async (carrier: Carrier, force: boolean) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: carrier.PartId },
		{ name: "LocationId", value: carrier.LocationId },
		{ name: "AssemblyStatusCode", value: carrier.AssemblyStatusCode },
		{ name: "CassetteId", value: carrier.CassetteId },
		{ name: "UpperRollId", value: carrier.UpperRollId },
		{ name: "LowerRollId", value: carrier.LowerRollId },
		{ name: "RollsetId", value: carrier.RollsetId },
		{ name: "UMChockId", value: carrier.UMChockId },
		{ name: "LMChockId", value: carrier.LMChockId },
		{ name: "UOChockId", value: carrier.UOChockId },
		{ name: "LOChockId", value: carrier.LOChockId },
		{ name: "EntryGuideId", value: carrier.EntryGuideId },
		{ name: "ExitGuideId", value: carrier.ExitGuideId },
		{ name: "Bait", value: carrier.Bait },
		{ name: "WeighUp", value: carrier.WeighUp },
		{ name: "ScheduledSectionId", value: carrier.ScheduledSectionId },
		{ name: "ScheduledLocationId", value: carrier.ScheduledLocationId },
		{ name: "Force", value: force ? 1 : 0},
	];

	const resp = await apiService.callV2("[M2].[AssembleCarrier]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting carriers");
	}
};

