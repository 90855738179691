import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { Chock } from "../../../models/parts/Chock";
import { getChocks } from "../../../repositories/parts/ChockRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableChock } from "./components/TableChock";

export const useChocks = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Chock[], Error>({
    queryKey: ["chocks", partId],
    queryFn: () => getChocks(partId),
    onError: onError,
  });
};

export const ChockPage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableChock />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
