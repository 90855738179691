import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Carrier } from "../../models/Carrier";

type Props = {
  setSelectedRowData: (carrier: Carrier) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useCarrierAssemblyOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: Carrier) => {
    let options: MenuOptionType[] = [];
    if (data.AssemblyStatusCode === "A") {
      options = options.concat([
        {
          name: "Assembly Carrier",
          key: "assembly_carrier",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
      ]);
    } else {
      options = options.concat([
        {
          name: "Disassembly Carrier",
          key: "disassembly_carrier",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
