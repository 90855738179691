import { MenuOptionType } from "@dexteel/mesf-core";
import EditIcon from "@material-ui/icons/Edit";
import { Part } from "../models/Part";

type Props = {
  setSelectedRowData: (arbor: Part) => void;
  setShowDetails: (
    modal: "U" | "E" | "H" | "C" | "G" | "A" | "T" | "K" | "D" | ""
  ) => void;
};
export const usePartsOptionsFunctions = ({
  setSelectedRowData,
  setShowDetails,
}: Props) => {
  const getMenuOptions = (data: Part) => {
    let options: MenuOptionType[] = [];
    if (data) {
      options = options.concat([
        {
          name: "View Part",
          key: "view_part",
          onClick: () => {
            setSelectedRowData(data);
            setShowDetails(`${data.PartTypeCode}`);
          },
          icon: <EditIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
