import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Roll } from "../models/Roll";

export interface FilterSearch {
  LocationId: number | null;
  SectionId: number | null;
  RollTypeCode: string | null;
}

export const getRolls = async () => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
  ];


  const resp = await apiService.callV2("[M2].[GetRolls]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Roll[];
  } else {
    throw new Error(resp.message || "Error fetching rolls");
  }
};
