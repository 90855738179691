import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Cassette } from "../../../../configuration/models/parts/Cassette";
import { Part } from "../../models/Part";
import { getCassettes } from "../../repositories/parts/CassetteRepository";

type useCassettesProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: Cassette[]) => void;
};

export const useCassettes = ({
  partId,
  onError,
  onSuccess,
}: useCassettesProps) => {
  return useQuery<Cassette[], Error>({
    queryKey: ["cassettes", partId],
    queryFn: () => getCassettes(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  part: Part | null;
};

export const SpecificCassetteData = ({ part }: Props) => {
  const [error, setError] = useState("");
  const [cassette, setCassette] = useState<Cassette>();

  const { data: cassettes } = useCassettes({
    partId: part?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: Cassette[]) => {
      setCassette(data[0]);
    },
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            value={cassette?.RivCassetteChockName || ""}
            label="River Cassette Chock"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            value={cassette?.AveCassetteChockName || ""}
            label="Avenue Cassette Chock"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
