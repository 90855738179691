import { Grid, TextField } from "@material-ui/core";
import { Part } from "../models/Part";
import {
  SpecificArborData,
  SpecificCarrierData,
  SpecificCassetteData,
  SpecificChockData,
  SpecificDonutData,
  SpecificEdgerRollData,
  SpecificGuideData,
  SpecificUniversalRollData,
} from "./parts-panels/specifics.datas";

type Props = {
  part: Part | null;
  showDetails: keyof typeof components;
};

const components = {
  A: SpecificArborData,
  H: SpecificCarrierData,
  T: SpecificCassetteData,
  C: SpecificChockData,
  D: SpecificDonutData,
  E: SpecificEdgerRollData,
  G: SpecificGuideData,
  U: SpecificUniversalRollData,
  K: null,
  "": null,
};

export const PartDetails = ({ part, showDetails }: Props) => {
  const hideTag = part?.PartTypeCode === "D";

  const SpecificComponent = components[showDetails];

  return (
    <>
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={part?.PartName || ""}
            label="Name"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        {!hideTag && (
          <Grid item md={6} sm={12} xs={12}>
            <TextField
              value={part?.RFIDTag}
              label="Tag"
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        )}
        <Grid item md={!hideTag ? 12 : 6} sm={12} xs={12}>
          <TextField
            value={part?.LocationName || ""}
            label="Location"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          {SpecificComponent && <SpecificComponent part={part} />}
        </Grid>
      </Grid>
    </>
  );
};
