import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export interface QuestionDialogRawProps {
  title: string;
  message?: string;
  open: boolean;
  onClose: (value?: string) => void;
}
export const QuestionDialogRaw = ({
  title,
  message,
  open,
  onClose,
  ...other
}: QuestionDialogRawProps) => {
  const handleCancel = () => {
    onClose("NO");
  };
  const handleOk = () => {
    onClose("YES");
  };
  return (
    <Dialog maxWidth="xs" open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          No
        </Button>
        <Button onClick={handleOk}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
