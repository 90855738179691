import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Cassette } from "../../models/parts/Cassette";

export const getCassettes = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetCassettes]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Cassette[];
	}
	else {
		throw new Error(resp.message || "Error fetching cassettes");
	}
};
export const upsertCassette = async (cassette: Cassette) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: cassette.PartId },
		{ name: "PartName", value: cassette.PartName },
		{ name: "LocationId", value: cassette.LocationId },
		{ name: "RivCassetteChockId", value: cassette.RivCassetteChockId },
		{ name: "AveCassetteChockId", value: cassette.AveCassetteChockId }
	]

	const resp = await apiService.callV2("[M2].[UpsertCassette]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting cassettes");
	}
};

export const deleteCassette = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteCassette]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting cassettes");
	}
};