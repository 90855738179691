import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { EdgerRoll } from "../../../models/parts/EdgerRoll";
import { getEdgerRolls } from "../../../repositories/parts/EdgerRollRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableEdgerRoll } from "./components/TableEdgerRoll";

export const useEdgerRolls = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<EdgerRoll[], Error>({
    queryKey: ["edgerRolls", partId],
    queryFn: () => getEdgerRolls(partId),
    onError: onError,
  });
};

export const EdgerRollPage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableEdgerRoll />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};