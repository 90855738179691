import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { CassetteChock } from "../../../models/parts/CassetteChock";
import { getCassetteChocks } from "../../../repositories/parts/CassetteChockRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableCassetteChock } from "./components/TableCassetteChock";

export const useCassetteChocks = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<CassetteChock[], Error>({
    queryKey: ["cassetteChocks", partId],
    queryFn: () => getCassetteChocks(partId),
    onError: onError,
  });
};

export const CassetteChockPage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableCassetteChock />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
