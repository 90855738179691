import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { rollPositionCodes } from "../../../../shared/selectors/RollPositionSelector";

const useStyles = makeStyles({
  colorCircle: {
    width: "26px",
    height: "26px",
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "bold",
  },
});
type StatusTagProps = {
  position: { Code: string; Name: string };
};
export const RollPositionTag = ({ position }: StatusTagProps) => {
  const classes = useStyles();

  if (!position || !position.Code || !position.Name) {
    return null;
  }
  let backgroundColor;
  if (
    position.Code === rollPositionCodes.find((code) => code.Code === "L")?.Code // Ready
  ) {
    backgroundColor = "#009460"; // Green
  } else if (
    position.Code === rollPositionCodes.find((code) => code.Code === "U")?.Code // In Process
  ) {
    backgroundColor = "#fad215"; // Yellow
  } else backgroundColor = "#ab9680"; // Brown

  return (
    <Tooltip title={position.Name} arrow>
      <div
        className={classes.colorCircle}
        style={{
          backgroundColor,
        }}
      >
        {position.Code.charAt(0).toUpperCase()}
      </div>
    </Tooltip>
  );
};
