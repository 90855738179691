import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { Section, SectionGap } from "../../../models/Section";
import { getSectionGaps } from "../../../repositories/SectionRepository";
import { SectionGapModal } from "./SectionGapModal";
import { useSectionsGapsGridData } from "./useSectionGapsGridData";

const useStyles = makeStyles(() => ({
  table: {
    height: "46.6vh",
  },
}));

type useSectionGapsProps = {
  sectionId?: number | null;
  onError: (error: Error) => void;
  onSuccess?: (data: any[]) => void;
};

export const useSectionGaps = ({
  sectionId,
  onError,
  onSuccess,
}: useSectionGapsProps) => {
  return useQuery<SectionGap[], Error>({
    queryKey: ["sectionGaps", sectionId],
    queryFn: () => getSectionGaps(sectionId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  section: Section;
  disabled?: boolean;
};

export const SectionGapsGrid = ({ section, disabled = false }: Props) => {
  const classes = useStyles();

  const [error, setError] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<SectionGap | null>();

  const { data: sectionGaps, isLoading } = useSectionGaps({
    sectionId: section?.SectionId,
    onError: (error) => setError(error.message),
  });

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  const { columnDefs } = useSectionsGapsGridData({
    setOpenEdit,
    setSelectedData,
  });

  return (
    <>
      <Grid item md={12} xs={12} className={classes.table}>
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact<SectionGap>
            rowData={sectionGaps}
            columnDefs={columnDefs}
            rowHeight={25}
            headerHeight={30}
            animateRows={true}
            suppressContextMenu
            loadingOverlayComponent={CenteredLazyLoading}
            onGridReady={(params) => setGridApi(params.api)}
            getRowId={(params) => String(params.data.StandId)}
          />
        </div>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
      <SectionGapModal
        show={openEdit}
        onHide={() => {
          setOpenEdit(false);
          setSelectedData(null);
        }}
        sectionGap={selectedData!}
      />
    </>
  );
};
