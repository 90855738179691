export interface Rollset {
	RollsetId: number | null;
    UpperRollName: string | null;
    UpperRollId: number | null;
    LowerRollName: string | null;
    LowerRollId: number | null;
    Comments: string;
}

export const ROLLSET_INITIAL_VALUES: Rollset = {
	RollsetId: null,
	UpperRollName: '',
	UpperRollId: null,
    LowerRollName: '',
	LowerRollId: null,
	Comments: '',
};

export interface RollsetRolls {
	RollsetId: number | null;
    PartId: number | null;
    IsDefaultUpperRoll: boolean;
    IsDefaultLowerRoll: boolean;
}