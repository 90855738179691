import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, Typography } from "@material-ui/core";
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import { useGridDefinitions } from "../../../../../../controls/ag-grid/components/GridDefinitions";
import { FooterButton } from "../../../../../../controls/buttons/FooterButton";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";

import { CassetteChock } from "../../../../models/parts/CassetteChock";
import { useGridStyles } from "../../../../styles/gridStyles";
import { useCassetteChocks } from "../CassetteChockPage";
import { useCassetteChockOptionsFunctions } from "../hooks/useCassetteChockOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { UpsertDeleteModal } from "./UpsertDeleteModal";

export const TableCassetteChock = () => {
  const classes = useGridStyles();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | ""
  >("");
  const [cassetteChockId, setCassetteChockId] = useState<number | null>(null);
  const [error, setError] = useState("");

  const { data: rows, isLoading } = useCassetteChocks(undefined, (error) =>
    setError(error.message)
  );

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useCassetteChockOptionsFunctions({
    setCassetteChockId,
    setShowModal,
  });
  const { columnDefs } = useTableData({
    setCassetteChockId,
    setShowModal,
    showContextMenu,
  });

  const { columnDefaults } = useGridDefinitions({
    OnEdit: (data: any) => {
      setShowModal("update");
      setCassetteChockId(data.Id);
    },
  });
  const onRowDoubleClicked = (event: any) => {
    setCassetteChockId((event.data as CassetteChock).PartId);
    setShowModal("update");
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const data = params.node?.data;
      showContextMenu(event as any, data, "cassetteChockContext");
      if (data) {
        params.api.deselectAll();
        params.node?.setSelected(true);
      }
      return [];
    },
    [rows]
  );

  const onModalHide = (shouldUpdate: boolean) => {
    if (shouldUpdate) gridApi?.paginationGoToLastPage();
    setShowModal("");
  };

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, rows, gridApi]);

  useEffect(() => {
    registerConfig({
      id: "cassetteChockContext",
      getOptions: getMenuOptions,
    });
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography
            variant="h5"
            style={{ margin: "1px 0 10px", fontWeight: 600 }}
          >
            Cassette Chocks
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.root}
          style={{ width: "100%" }}
        >
          <Grid
            item
            md={12}
            xs={12}
            style={{ padding: "0 15px" }}
            className={classes.relative}
          >
            <Grid item md={12} xs={12} className={classes.table}>
              <div
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
              >
                <AgGridReact<CassetteChock>
                  rowData={rows || []}
                  columnDefs={columnDefs}
                  defaultColDef={columnDefaults}
                  rowHeight={34}
                  headerHeight={34}
                  animateRows={true}
                  pagination={true}
                  rowSelection="single"
                  paginationPageSize={10}
                  getContextMenuItems={getContextMenuItems}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onGridReady={(params) => setGridApi(params.api)}
                  getRowId={(params) => String(params.data.PartId)}
                  loadingOverlayComponent={CenteredLazyLoading}
                />
              </div>
            </Grid>
            <FooterButton
              label="CASSETTE CHOCK"
              showCreateModal={showModal === "create"}
              setShowCreateModal={(showCreateModal) =>
                showCreateModal ? setShowModal("create") : setShowModal("")
              }
              sizes={{ md: 3 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <UpsertDeleteModal
        CassetteChockId={cassetteChockId}
        onHide={onModalHide}
        modal={showModal}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
