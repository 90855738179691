import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, IconButton, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Rollset } from "../models/Rolllset";
import { FilterSearch } from "../repositories/FilterRollsRepository";
import { getRollsets } from "../repositories/RollsetRepository";

type useRollsetsProps = {
  onError: (error: Error) => void;
  onSuccess?: (data: Rollset[]) => void;
  filterSearch?: FilterSearch | undefined;
};

export const useRollsets = ({
  filterSearch,
  onError,
  onSuccess,
}: useRollsetsProps) => {
  return useQuery<Rollset[], Error>({
    queryKey: ["rollsets", filterSearch],
    queryFn: () => getRollsets(filterSearch),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  filterSearch?: FilterSearch | undefined;
  label: string;
  value: number | null;
  onChange?: Function;
  setShowFilterModal?: (show: boolean) => void;
  disabled?: boolean;
};
export const RollsetSelector = ({
  filterSearch,
  label,
  value,
  onChange = () => {},
  setShowFilterModal,
  disabled = false,
}: Props) => {
  const [error, setError] = useState("");

  const { data: rollsets, isLoading } = useRollsets({
    filterSearch: filterSearch,
    onError: (error) => setError(error.message),
  });

  const selectedValue =
    rollsets?.find((rollset) => rollset.RollsetId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={rollsets || []}
        getOptionLabel={(option) => `Rollset ${option.RollsetId}`}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <>
                      {setShowFilterModal ? (
                        <IconButton
                          disabled={disabled}
                          onClick={() => setShowFilterModal(true)}
                          style={{ padding: 4, zIndex: 1 }}
                        >
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.RollsetId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading rollsets"
      />
    </>
  );
};
