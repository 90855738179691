import { ErrorModal } from "@dexteel/mesf-core";
import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FieldError } from "react-hook-form";
import { useAutocompleteOptionStyles } from "../../utils/theme";
import { Roll } from "../models/Roll";
import { getRolls } from "../repositories/RollRepository";

type Props = {
  label: string;
  value: number | null;
  onChange?: Function;
  fieldError?: FieldError | undefined;
  disabled?: boolean;
  filterRolls?: "U" | "L" | "B";
};

type useRollsProps = {
  onError: (error: Error) => void;
  onSuccess?: (data: Roll[]) => void;
};

export const useRolls = ({ onError, onSuccess }: useRollsProps) => {
  return useQuery<Roll[], Error>({
    queryKey: ["rolls"],
    queryFn: () => getRolls(),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export const RollSelector = ({
  label,
  value,
  onChange = () => {},
  fieldError,
  disabled = false,
  filterRolls = "B",
}: Props) => {
  const classes = useAutocompleteOptionStyles();

  const [error, setError] = useState("");

  const { data: rolls, isLoading } = useRolls({
    onError: (error) => setError(error.message),
  });

  const selectedValue = rolls?.find((roll) => roll.PartId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={
          rolls?.filter(
            (roll) =>
              roll.RollPosition === filterRolls || roll.RollPosition === "B"
          ) || []
        }
        getOptionLabel={(option) => option.PartName}
        renderOption={(props, option) => {
          return (
            <Box className={classes.optionBox}>
              <span>
                <Typography>{props.PartName}</Typography>
              </span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "8px",
                  marginTop: 7,
                }}
              >
                {props?.Sections && (
                  <Chip
                    size="small"
                    variant="outlined"
                    color="secondary"
                    label={props?.Sections ?? "-"}
                  />
                )}
                {props?.Stands && (
                  <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={props?.Stands ?? "-"}
                  />
                )}
                <Chip
                  size="small"
                  variant="outlined"
                  color="default"
                  label={
                    props?.RollPosition === "U"
                      ? "Upper"
                      : props.RollPosition === "L"
                      ? "Lower"
                      : "Both"
                  }
                />
              </div>
            </Box>
          );
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            error={!!fieldError}
            helperText={fieldError?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.PartId);
        }}
      />
      <Collapse in={!!selectedValue} style={{ margin: 5 }}>
        <Typography variant="body2" color="textSecondary">
          {`Sections: ${selectedValue?.Sections ?? "-"}
          | Stands: ${selectedValue?.Stands ?? "-"}
          `}
        </Typography>
      </Collapse>
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading rolls"
      />
    </>
  );
};
