import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { Arbor } from "../../../../models/parts/Arbor";

type Props = {
  setSelectedRowData: (arbor: Arbor) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useArborOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: Arbor) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Arbor",
        key: "new_arbor",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Arbor",
          key: "edit_arbor",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Arbor",
          key: "delete_arbor",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
