import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { Guide } from "../../../models/parts/Guide";
import { getGuides } from "../../../repositories/parts/GuideRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableGuide } from "./components/TableGuide";

export const useGuides = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Guide[], Error>({
    queryKey: ["guides", partId],
    queryFn: () => getGuides(partId),
    onError: onError,
  });
};

export const GuidePage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableGuide />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
